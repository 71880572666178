import React, { Component } from 'react';
import CreditCardInformationSection from '../../../sections/CreditCardInformationSection/CreditCardInformationSection';
import MemberInformationSection from '../../../sections/MemberInformationSection/MemberInformationSection';
import NextPageButton from '../../../buttons/NextPageButton/NextPageButton';
import FormService from '../../../../services/FormService';
import history from "../../../../history";
import StartupService from '../../../../services/StartupService';
import axios from 'axios';
import { LOBProducts } from '../../../../classes/LOBProducts';
import LoadingScreenService from '../../../../services/LoadingScreenService';
import { EPaymentFormComponent } from '../../../../classes/EPaymentFormComponent';
import { CreditCardCompanies } from '../../../../classes/CreditCardCompanies';
import FindFormSettingService from '../../../../services/FindFormSettingService';
import LocaleService from '../../../../services/LocaleService';
import InvisibleReCaptcha from '../../../ReCaptcha/InvisibleReCaptcha';

class StepFillFormUpdateCC extends EPaymentFormComponent {
  
  FORM_ID = "update-cc-form";
  CARD_INFORMATION_ROUTE = "tokenization/generate-card-information-access-key"


    constructor(props: any) {
      super(props);
    }


    /**
     * Handle iframe moneris response
     * @param code - Moneris iframe response code
     */
    manageResponseCode(code: string, recaptchaValue: string) {
      if (code === '000') {
        const temporaryToken = window['AK_iFrame_Data'].token;
        if (temporaryToken !== '') {
          this.currentTemporaryToken = temporaryToken;
          this.createPermanentToken(recaptchaValue);
        }
      } else {
        switch(code){
          case '902':
          case '920':
            this.setCardVerificationFailed(true, "ErrorMessage_CardInvalidNumber");
            break;
          case '930':
            this.setCardVerificationFailed(true, "ErrorMessage_CardInvalidDate");
            break;
          case '940':
            this.setCardVerificationFailed(true, "ErrorMessage_CardInvalidCVV");
            break;
          case '999':
            this.setCardVerificationFailed(true, ["UpdateCC_ConfirmationFailSubTitleLine1","UpdateCC_ConfirmationFailSubTitleLine2"]);
            break;
          default:
            this.setCardVerificationFailed();
            break;
        }

        LoadingScreenService.disableLoadingScreen();
      }
    }

    public createPermanentToken(recaptchaValue: string): void {  
       var url = StartupService.getApiUrl() + this.CARD_INFORMATION_ROUTE;
       //TODO: Send google reCaptcha token
       axios.post(url, {
         lineOfBusinessCode: LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).lineOfBusinessCode,
         productCode: LOBProducts.getProductObjectForOffer(StartupService.getProductOffer()).productCode,
         customerID: this.getForm().fields.gpn,
         temporaryCardInformationAccessKey: this.currentTemporaryToken,//"ot-SeYy4b7ep0VhukoPYVHzTn2w2",//temporaryToken,
         cardInformationUseIndicator: "FutureUseECommerce", // 11 - FutureUseECommerce
         gRecaptchaResponse: recaptchaValue
       })
       .then((response) => {
         if(response.data.statusCode !== "908"){
           FormService.setMaskPAN(response.data.content.cardInformation.maskedPAN);
  
           if(!FormService.isValidCreditCardCompany(FormService.getCreditCardFirstDigits())){
             if(FindFormSettingService.findSetting("AuthorizedCreditCardCompanies")){
               this.setCardVerificationFailed(true, {
                 message: "ErrorMessage_CardInvalidCompany", 
                 args: [CreditCardCompanies.getCreditCardCompanyNameForCompanies(FindFormSettingService.findSetting("AuthorizedCreditCardCompanies"))]
               });
             } else {
               this.setCardVerificationFailed(true, {
                 message: "ErrorMessage_CardInvalidCompany", 
                 args: [LocaleService.getLanguage() === 'en' ? 'none' : 'aucun']
               });
             }
           } else {
             FormService.setCardInformationAccessKey(response.data.content.cardInformationAccessKey);
  
             //window.location.hash = `/${StartupService.getProductOffer()}/update-cc/review`;
             history.push(`/${StartupService.getProductOffer()}/update-cc/review`);
             window.scrollTo({top: 0, behavior: 'smooth'});
           }
  
           LoadingScreenService.disableLoadingScreen();
         }
         else {
           this.setCardVerificationFailed();
           LoadingScreenService.disableLoadingScreen();
           console.error("Card Information Post Req - Failed: ", response.data.message);
         }
       })
       .catch(() => {
           this.setCardVerificationFailed();
           LoadingScreenService.disableLoadingScreen();
       });
    }
    
    render() {
      return (
        <div className="step-one">
          <form id="update-cc-form" onSubmit={this.onSubmit}>
            <MemberInformationSection getParentForm={this.getForm} parentHandleInputChange={this.handleInputChange} />
            <CreditCardInformationSection parentEventEmitter={this.eventEmitter} displayAddress={true} getParentForm={this.getForm} parentHandleInputChange={this.handleInputChange} displayCardType={FindFormSettingService.findSetting("UpdateCCType")}/>
            <InvisibleReCaptcha recaptchaRef={this.recaptchaRef} handleCaptchaChange={this.handleReCaptchaChange}/>
            <NextPageButton btnDisabled={!this.state.isFormVerified}/>
          </form>
        </div>
      );
    }
  }
  
  
export default StepFillFormUpdateCC;
  