import React, { Component } from 'react';
import './InvisibleReCaptcha.scss'
import ReCAPTCHA from "react-google-recaptcha";
import MonerisService from '../../services/MonerisService';


interface  InvisibleReCaptchaProps {
  recaptchaRef: React.RefObject<any>;
  handleCaptchaChange: any;
}


class InvisibleReCaptcha extends Component<InvisibleReCaptchaProps> {

    recaptchaRef: React.RefObject<any>;
    handleCaptchaChange: any;

    constructor(props: any) {
      super(props);
      
      this.recaptchaRef = props.recaptchaRef;
      this.handleCaptchaChange = props.handleCaptchaChange;
    }
  

    render() {
  
      return (
        <ReCAPTCHA
        ref={this.recaptchaRef}
        size="normal"
        onChange={this.handleCaptchaChange}
        sitekey={MonerisService.getReCaptchaSiteKey()}
      />
      );
    }
  }
  
  
export default InvisibleReCaptcha;
  